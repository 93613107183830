import React from 'react'
import SiteTitle from '../Components/SiteTitle'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import BlankPFP from '../Images/BlankPFP.png'
import KevinPFP from '../Images/KevinPFP.png'
import ThanhPFP from '../Images/ThanhPFP.png'
import JackPFP from '../Images/JackPFP.png'
import BrendanPFP from '../Images/BrendanPFP.png'
import BlakePFP from '../Images/BlakePFP.jpg'

function OurTeam() {
    SiteTitle("Bizcomm | Our Team")
    return (
        <>

            <section id='team-splash' class='section splash'>

                <div id='home-splash-header'>

                    <h1>Our Team</h1>

                </div>

            </section>

            <ThemeProvider
                theme={createTheme({
                    breakpoints: {
                        values: {
                            desktop: 1200,
                            laptop: 900,
                            tablet: 600,
                            mobile: 0,
                        },
                    },
                })}
            >

                <section id='team-meet' class='section'>

                    <h1>Meet the team</h1>

                    <section class='grid-container'>

                        <Grid container spacing={8} columns={12}>

                            <Grid mobile={6} tablet={4} laptop={3}>

                                <article class="team-member">

                                    <img class="team-member-img" src={KevinPFP} alt='Kevin Lau' />

                                    <div class="team-member-content">

                                        <h2 style={{textAlign: 'center'}}>Kevin Lau</h2>
                                        <h3>Field Engineer</h3>
                                        <a href='https://www.linkedin.com/in/kevin-lau-032bb29a/' target='_blank' rel='noreferrer'>LinkedIn</a>

                                    </div>

                                </article>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={3}>

                                <article class="team-member">

                                    <img class="team-member-img" src={ThanhPFP} alt='Thanh Ton' />

                                    <div class="team-member-content">

                                        <h2 style={{textAlign: 'center'}}>Thanh Ton</h2>
                                        <h3>Solutions Consultant</h3>
                                        <a href='https://www.linkedin.com/in/thanh-ton/' target='_blank' rel='noreferrer'>LinkedIn</a>

                                    </div>

                                </article>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={3}>

                                <article class="team-member">

                                    <img class="team-member-img" src={JackPFP} alt='Jack Tollofsen' />

                                    <div class="team-member-content">

                                        <h2 style={{textAlign: 'center'}}>Jack Tollefsen</h2>
                                        <h3>Customer Contact Consultant</h3>
                                        <a href='https://www.linkedin.com/in/jack-tollefsen-64bbab176/' target='_blank' rel='noreferrer'>LinkedIn</a>

                                    </div>

                                </article>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={3}>

                                <article class="team-member">

                                    <img class="team-member-img" src={BrendanPFP} alt='Brendan Nguyen' />

                                    <div class="team-member-content">

                                        <h2 style={{textAlign: 'center'}}>Brendan Nguyen</h2>
                                        <h3>Customer Contact Consultant</h3>
                                        <a href='https://www.linkedin.com/in/brendan-nguyen-7737121a3/' target='_blank' rel='noreferrer'>LinkedIn</a>

                                    </div>

                                </article>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={3}>

                                <article class="team-member">

                                    <img class="team-member-img" src={BlankPFP} alt='Victor Quocson' />

                                    <div class="team-member-content">

                                        <h2 style={{textAlign: 'center'}}>Victor Quocson</h2>
                                        <h3>Customer Contact Consultant</h3>
                                        <a href='https://www.linkedin.com/in/victor-quocson-723806180/' target='_blank' rel='noreferrer'>LinkedIn</a>

                                    </div>

                                </article>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={3}>

                                <article class="team-member">

                                    <img class="team-member-img" src={BlankPFP} alt='Lachlan Brennan' />

                                    <div class="team-member-content">

                                        <h2 style={{textAlign: 'center'}}>Lachlan Brennan</h2>
                                        <h3>Customer Consultant</h3>
                                        <a href='https://www.linkedin.com/in/lachlan-brennan-b1ab2031a/' target='_blank' rel='noreferrer'>LinkedIn</a>

                                    </div>

                                </article>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={3}>

                                <article class="team-member">

                                    <img class="team-member-img" src={BlankPFP} alt='Kayley Nguyen' />

                                    <div class="team-member-content">

                                        <h2 style={{textAlign: 'center'}}>Kayley Nguyen</h2>
                                        <h3>Customer Contact Trainee</h3>
                                        <a href='' target='_blank' rel='noreferrer'>LinkedIn</a>

                                    </div>

                                </article>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={3}>

                                <article class="team-member">

                                    <img class="team-member-img" src={BlakePFP} alt='Blake Ure' />

                                    <div class="team-member-content">

                                        <h2 style={{textAlign: 'center'}}>Blake Ure</h2>
                                        <h3>Network Engineer</h3>
                                        <a href='https://www.linkedin.com/in/blake-ure-511526194/' target='_blank' rel='noreferrer'>LinkedIn</a>

                                    </div>

                                </article>

                            </Grid>

                        </Grid>

                    </section>

                </section>

            </ThemeProvider>

        </>
    )
}

export default OurTeam